import React from "react";
import contactBanner from "../assets/png/ContactBanner.png";
import styles from "./page.module.scss";

const Contact = () => {
  return (
    <>
      <div className={styles.bannerOne}>
        <div className={styles.Image}>
          <img src={contactBanner} alt="About" draggable={false} />

          <strong className={styles.screenName}>Get in Touch</strong>
        </div>
      </div>
      <div className={styles.contactBanner}>
        <div className={styles.container}>
          <div className={styles.contactFlex}>
            <div className={styles.information}>
              <h1 className={styles.informationHeading}>
                Get in touch with us
              </h1>
              <p className={styles.informationContent}>
                Connect with us for tailored logistics solutions. Your inquiries
                are our priority. Reach out and experience seamless shipping
                with Nekoda
              </p>
              <div className={styles.valueGroup}>
                <label className={styles.label}>Support Center 24/7</label>
                <div className={styles.value}>
                  <a href="tel:+91 9940681629" className={styles.linkPrimary}>
                    +91 9940681629
                  </a>
                </div>
              </div>
              <div className={styles.valueGroup}>
                <label className={styles.label}>Our Location</label>
                <div className={styles.value}>
                  Nekoda Global Logistics India Pvt. Ltd. <br /> No.7, 2nd
                  Floor, <br />
                  Venkataswamy Street, <br /> Chetpet, Chennai-600 031, India.
                </div>
              </div>
              <div className={styles.valueGroup}>
                <label className={styles.label}>Write to us</label>
                <div className={styles.value}>
                  <a
                    href="mailto:info@nekoda.in"
                    className={styles.linkPrimary}>
                    info@nekoda.in
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.formBanner}>
              <div className={styles.container}>
                <form className={styles.form}>
                  <div className={styles.formGroup}>
                    <input
                      type="text"
                      className={styles.formControl}
                      placeholder="Your Name"
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <input
                      type="email"
                      className={styles.formControl}
                      placeholder="Your eMail"
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <textarea
                      placeholder="Message"
                      className={styles.formControl}
                    />
                  </div>
                  <br />

                  <button className={styles.button}>Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mapBanner}>
        <iframe
          title="Map"
          src="https://www.google.com/maps/embed/v1/place?q=nekoda%20logistics&key=AIzaSyCFW8-jvIL3tcje30OAN2zTX9a3k0LW6uI"
          width="100%"
          height="100%"
          style={{ border: "none" }}
          loading="lazy"></iframe>
      </div>
    </>
  );
};

export default Contact;
